@mixin techBtn {
  background: var(--color-green-10);
  border-radius: 8px;
  border: none;
  font-family: var(--font-gilroy);
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  padding: 8px 16px;
  text-align: justify;
  letter-spacing: 0.045em;
  color: var(--color-green-7);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px 15px 0;
  transition: all 1s ease;
}

.technologiesBtnList {
  display: flex;
  flex-wrap: wrap;
  .technologiesBtn {
    @include techBtn;
  }
  .includedTechnologiesBtn {
    @include techBtn;
    background-color: var(--color-green-11);
    color: var(--color-white);
  }

  .iconContainer {
    height: 18px;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      margin-right: 4px;
      max-width: 22px;
    }
  }
}
