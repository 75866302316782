@import '../../../styles/mixins';

.infoSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0 23px 0;
  @include md {
    padding: 0 0 0 10px;
  }
  @include lt-sm {
    margin: 18px 0 0 0;
  }
  .title {
    font-family: 'Futura New';
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #0a3944;
    margin: 0;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
    height: 38px;
    @include lt-md {
      width: 100%;
    }
  }
}
