@import '../../../../styles/mixins';

@mixin imageContainer {
  margin: 50px 0 0 0;
  width: 180px;
  img {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  @include md {
    margin: 80px 0 0 0;
  }

  @include lt-sm {
    margin: 67px 0 0 0;
    width: 140px;
    height: 120px;
  }
}

@mixin paragraph {
  margin: 34px 0 0 0;
  font-family: var(--font-gilroy);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--blue-whale);

  @include md {
    margin: 30px 0 0 0;
  }

  @include lt-sm {
    display: none;
  }
}

/*======Rules========*/
.visibleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .screenshotContainer {
    @include imageContainer;
  }
  .cvContainer {
    @include imageContainer;
    width: 148px;
    @include lt-sm {
      margin: 40px 0 0 0;
      height: 173px;
    }
  }
  .paragraph {
    @include paragraph;
    max-width: 265px;
  }
  .shortParagraph {
    @include paragraph;
    max-width: 127px;
  }
}
.hiddenContainer {
  display: none;
}
.uploadedImagesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .uploadedImage {
    position: relative;
    padding: 5px;
    .urlImagesContainer {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 200px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
      }
      @include lt-sm {
        width: 97px;
      }
    }
    .cancelButton {
      z-index: 5;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(--color-white);
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.cancelButtonPdfFile {
  width: 40px;
  position: absolute;
  z-index: 5;
  top: 0;
  right: 40px;
  padding: 7px;
  background-color: var(--color-white);
  border-radius: 4px;
}
