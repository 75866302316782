.login-container {
  display: flex;
  align-items: center;
  height: 100vh;
  .login-wallpaper {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: inherit;
    width: 1110px;
    background-image: url('../../../assets/png/login-wallpaper.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -10px 0;
    @media only screen and (max-width: 960px) {
      width: 525px;
      background-position: -300px 0;
    }
  }
}
