@import './mixins.scss';

:root {
  /* colors */
  --color-black: hsla(0, 0%, 0%, 1);
  --color-dark: hsl(191deg, 30%, 14%, 1);
  --color-dark-2: #18292d;
  --color-green-1: hsl(190deg, 77%, 22%, 1);
  --color-green-2: hsl(189deg, 77%, 31%, 1);
  --color-green-3: hsl(185deg, 32%, 46%, 1);
  --color-green-4: hsl(175deg, 31%, 67%, 1);
  --color-green-5: hsl(185deg, 55%, 96%, 1);
  --color-orange: hsl(8deg, 63%, 57%, 1);
  --color-white: hsl(0deg, 0%, 100%, 1);
  --bg-light-blue: hsl(185deg, 55%, 96%, 1);

  --color-green-6: hsla(191, 74%, 15%, 1);
  --color-green-7: hsla(186, 100%, 26%, 1);
  --color-green-8: hsla(186, 100%, 26%, 0.1);
  --color-green-9: hsla(186, 100%, 26%, 0.2);
  --color-green-10: hsla(188, 84%, 29%, 0.1);
  --color-green-11: hsla(188, 84%, 29%, 1);
  --color-green-12: hsl(193, 82%, 15%);
  --color-red: hsla(8, 60%, 56%, 1);
  --color-grey: hsla(0, 0%, 0%, 0.25);
  --color-green-light-1: hsl(165, 22%, 70%);

  /* gradients */
  --gradient-green: linear-gradient(90deg, var(--color-green-1) 0%, var(--color-green-2) 100%);

  /* fonts */
  --font-gilroy: 'Gilroy';
  --font-futura-new: 'Futura New';

  .btn {
    transition: 0.2s all ease-out;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    color: #18292d;
    margin: 3px;

    &:not(.btn-sm) {
      border-radius: 30px;
      font-size: 18px;
      line-height: 137.8%;
      padding: 17px 70px;
    }

    &-secondary {
      color: #ffffff;
    }
  }

  .button-s {
    padding: 8px 14px;
    font-weight: 600;
    font-family: 'Gilroy';

    img,
    svg {
      margin-right: 8px;
    }

    &.download {
      color: #d25f4d;
    }

    &.edit {
      color: #007987;
    }
  }

  .flags-input {
    background: white;
    padding: 0;
    color: black;
    border-radius: 8px;
  }

  .custom-badge {
    padding: 8px 16px;
    margin: 0 8px 12px 0;

    background: rgba(12, 121, 137, 0.1);
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.045em;

    color: #007987;

    .technology-icons {
      margin-right: 8px;
    }
  }

  .page-container {
    padding: 0 24px 50px;

    @include gt-md {
      padding: 0 0 50px;
    }
  }
}
