.googleBtnContainer {
  width: 372px;
  padding: 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title {
    margin: 0 0 0 25px;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    font-family: var(--font-futura-new);
    color: var(--color-green-12);
  }
}
