@import '../../../styles/mixins';
@mixin paragraph {
  max-width: 205px;
  margin: 53px 0 0 0;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--blue-whale);

  @include md {
    margin: 30px 0 0 0;
  }

  @include lt-sm {
    display: none;
  }
}

.activeDropArea {
  width: 510px;
  height: 510px;
  background: var(--color-green-8);
  border: 3px dashed var(--color-green-9);
  border-radius: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: var(--font-gilroy);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-green-6);
  @include md {
    height: 450px;
  }
  @include lt-sm {
    width: 340px;
    height: 340px;
  }
}
.dropArea {
  width: 510px;
  height: 510px;
  background: var(--color-green-8);
  border: 2px solid var(--color-green-9);
  border-radius: 10px;
  @include md {
    height: 450px;
  }

  @include lt-sm {
    width: 340px;
    height: 340px;
  }

  .toDropArea {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .uploadedImageContainer {
      display: contents;
      img {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    .imageContainer {
      margin: 107px 0 0 0;
      img {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      @include md {
        margin: 80px 0 0 0;
      }

      @include lt-sm {
        margin: 67px 0 0 0;
      }
    }
    .paragraph {
      @include paragraph;
    }
    .uploadBtn {
      cursor: pointer;
      width: 260px;
      position: absolute;
      padding: 18px 71px;
      background: var(--color-green-7);
      border-radius: 70px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 37px;
      color: var(--color-white);
      font-family: var(--font-gilroy);
      font-weight: 700;
      font-size: 18px;
      line-height: 137.8%;
      white-space: nowrap;
      transition: all 0.5s ease-in;
      &:hover {
        background-color: #025964;
      }
      @include md {
        margin-bottom: 0;
      }
      @include lt-sm {
        margin-bottom: 0;
      }
    }
    .uploadInput {
      visibility: hidden;
    }
    .cancelButton {
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      background-color: var(--color-white);
      border-radius: 4px;
    }
  }
}
