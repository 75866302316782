.project-preview {
  background-color: white;
  border-radius: 15px;
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 25px 30px;

  &.selected {
    border: 1px solid var(--color-green-2);
  }

  &-logo {
    width: 70px;
    height: 70px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    padding-left: 34px;
  }

  &-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #004959;
    margin-bottom: 15px;
  }

  &-location {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #004959;
    img {
      margin-right: 10px;
    }
  }

  &-overview {
    height: 126px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: start;
    text-indent: 16px;
    letter-spacing: 0.045em;
    color: #18292d;
    padding: 20px 0;
  }

  &-dates {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #004959;
    padding: 16px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
  &-footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
  }
}
