@import '../../../styles/mixins';

@mixin paragraph {
  margin: 34px 0 0 0;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--color-green-6);

  @include md {
    margin: 30px 0 0 0;
  }

  @include lt-sm {
    display: none;
  }
}

@mixin imageContainer {
  margin: 50px 0 0 0;
  width: 180px;
  img {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  @include md {
    margin: 80px 0 0 0;
  }

  @include lt-sm {
    margin: 67px 0 0 0;
    width: 140px;
    height: 120px;
  }
}

@mixin dropArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin uploadButton {
  position: absolute;
  padding: 18px 71px;
  width: 260px;
  background: var(--color-green-7);
  border-radius: 70px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 20px;
  color: var(--color-white);
  font-family: var(--font-gilroy);
  font-weight: 700;
  font-size: 18px;
  line-height: 137.8%;
  white-space: nowrap;
  text-align: center;
  z-index: 6;
  transition: all 0.5s ease-in;
  &:hover {
    background-color: #025964;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lt-sm {
    margin-bottom: 0;
  }
}

/*=================Rules======================*/
.activeDropArea {
  height: 441px;
  width: 100%;
  background: var(--color-green-8);
  border: 3px dashed rgba(0, 121, 135, 0.2);
  border-radius: 10px;
  font-family: var(--font-gilroy);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--blue-whale);
  display: flex;
  align-items: center;
  justify-content: center;
  @include lt-sm {
    height: 340px;
  }
}
.screenshotsArea {
  height: 441px;
  width: 100%;
  background: var(--color-green-8);
  border: 2px solid var(--color-green-9);
  border-radius: 10px;
  overflow: auto;
  position: relative;
  @include lt-sm {
    height: 340px;
  }

  .toDropArea {
    @include dropArea;
    .uploadInput {
      visibility: hidden;
    }

    .uploadBtn {
      @include uploadButton;
    }
  }
  .toDropAreaWithImg {
    @include dropArea;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    .uploadInput {
      visibility: hidden;
    }
    .uploadedImagesContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .uploadedImage {
        position: relative;
        padding: 5px;
        .urlImagesContainer {
          display: flex;
          justify-content: center;
          flex-direction: row;
          width: 200px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
          }
          @include lt-sm {
            width: 97px;
          }
        }
        .cancelButton {
          width: 30px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: var(--color-white);
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    .uploadBtn {
      @include uploadButton;
    }
  }
}
