header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 34px 0 46px 0;
  nav {
    width: 100%;
  }
}

.navigation {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0 50px;
  margin: 0;
  margin-left: 50px;

  &-item {
    font-size: 1.125rem;
    line-height: 1;
    display: block;
    padding: 12px 0;
    position: relative;

    a,
    a:visited {
      color: unset;
      margin: 0;
    }
  }
  &-item {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #0c7888;
      transition: 0.2s all ease;
      transform: translateX(50%);
      width: 0%;
      opacity: 0;
    }
  }
  &-item:hover,
  &-item.active {
    &::after {
      transform: translateX(0);
      width: 100%;
      opacity: 1;
    }
  }
}
