.navbar {
  padding-bottom: 28px;

  .container {
    background: white;
    border-radius: 15px;
    box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 18px;
    max-height: 74px;

    a {
      margin: 0;

      &.logo {
        img {
          width: 69px;
          height: 41px;
        }
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    // padding-left: 50px;
  }
}

.filter-options {
  .icon {
    margin-left: 10px;
    width: 20px;
    aspect-ratio: 1/1;
  }
}

.search {
  padding-right: 25px;
  background-color: white;

  &.shadow {
    padding-right: 0;
    border-radius: 15px;
    input {
      margin-right: 0;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1), 5px 4px 30px rgb(0 0 0 / 10%);
    }
  }

  img {
    cursor: pointer;
  }

  input {
    background: #ffffff;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 254px;
    height: 35px;
    border: none 0;
    padding: 0 22px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: rgb(0, 0, 0);
    margin-right: 32px;
  }

  ::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.27);
  }
}
