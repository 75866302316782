.card {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(12, 121, 137, 0.21);
  border-radius: 15px;
  transition: 0.15s all ease-in;
  width: 365px;
  padding: 0 !important;

  &.active,
  &:focus,
  &:hover {
    box-shadow: 0px 0px 15px #0c7989;
  }
}
