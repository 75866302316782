@import '../../styles/_mixins.scss';

.swiperWrapper {
  height: 320px;
  margin-bottom: 32px;
}

.swiperSlide {
  border-radius: 10px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sliderControls {
  display: none;
  align-items: center;
  justify-content: flex-end;

  @include gt-xs {
    display: flex;
  }

  .pagination {
    max-width: 50px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0a3944;
  }

  .navigationBlock {
    display: flex;

    .arrowNext {
      transform: rotate(180deg);
    }
  }
}
