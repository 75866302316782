@import '../../../styles/_mixins.scss';

.pageWrapper {
  padding: 0 20px 57px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(12, 121, 137, 0.1);

  @include gt-sm {
    padding: 0 80px 100px;
  }

  .logoWrapper {
    height: 100%;
    max-width: 547px;
    max-height: 300px;
    margin-bottom: 40px;
    overflow: hidden;

    .logoImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoBlock {
    .infoBlockTitle {
      font-family: 'Futura New';
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: #0a3944;
    }

    .infoBlockInfo {
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #0a3944;
    }
  }
}
