.pageButtons {
  display: flex;
  justify-content: space-between;

  margin-bottom: 27px;
}

.pageWrapper {
  padding: 25px;

  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(12, 121, 137, 0.1);

  .cvWrapper {
    display: flex;
  }

  .pdf {
    width: 100%;
  }
}

.cvContent {
  padding: 50px;
  display: flex;
  flex-direction: column;

  .cvMainInfoWrapper {
    min-height: 700px;
    margin-left: 450px;
    margin-bottom: 15px;
  }

  .cvMainInfo {
    margin-bottom: 15px;

    .cvMainInfoTitle {
      font-family: 'Futura New';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;

      color: #0a3944;
    }

    .cvMainInfoContent {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      color: #0a3944;
    }
  }
}

.cvBlockWrapper {
  margin-bottom: 20px;
}

.cvBlockTitle {
  color: #0c7989;
  font-weight: 400;
  margin-bottom: 20px;
}

.cvBlockContentWrapper {
  display: flex;
  margin-bottom: 8px;

  .leftSide {
    width: 40%;
    font-weight: bold;
  }

  .rightSide {
    width: 60%;
    line-height: 140%;
    font-weight: 400;
    font-family: 'Gilroy';
  }
}
