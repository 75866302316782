@mixin subtitle {
  letter-spacing: 0.03em;
  font-size: 16px;
  font-family: var(--font-futura-new);
  color: #636364;
  font-weight: 450;
  text-align: center;
}

.auth-form-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: inherit;
  width: 40vw;
  @media only screen and (max-width: 960px) {
    width: 100vw;
  }
}

.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: inherit;
  padding: 2rem;
  &_input {
    width: 372px;
    padding: 13px 0;
    label {
      font-family: 'Futura New';
      font-weight: 600;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 20px;
      color: #0d5563;
    }
    input {
      border: 1px solid rgba(0, 0, 0, 0.25);
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.025));
      border-radius: 57px;
      height: 50px;
      padding: 0 1.75rem;
      &:focus {
        border-color: #a3c4bc;
        box-shadow: 0 0 0 0.075rem #90c5c1;
      }
      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  &_submit {
    margin: 18px 0 24px 0;
    height: 60px;
    min-width: 372px;
  }
  &_signup {
    font-family: 'Futura New';
    font-weight: 450;
    font-size: 15px;
    letter-spacing: 0.03em;
    color: #636364;
    margin: 21px 0 0 0;
    a {
      margin: 2px;
      color: #0c7989;
    }
  }
  &_copy {
    justify-content: center;
    align-items: center;
    padding-bottom: 18px;
    &-title {
      font-family: 'Futura New';
      letter-spacing: 0.03em;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #007987;
    }
    &-subtitle {
      @include subtitle;
    }
  }
  &_subtitle {
    @include subtitle;
    margin: 31px 0 21px 0;
  }
}
