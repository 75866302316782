.filter-item-expander {
  min-width: 90px;
  margin-right: 23px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0;

  span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .icon {
    margin-right: 8px;
  }

  img:not(.icon) {
    margin-left: 18px;
    width: 20px;
    height: 20px;

    &.active {
      transform: rotateX(180deg);
    }
  }
}
