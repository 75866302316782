/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

$primary: #90c5c1;
$secondary: #3394a4;
$body-bg: #fff;

@import '~bootstrap/scss/bootstrap.scss';
@import './styles/theme.scss';

@import './styles/fonts/futura.scss';
@import './styles/fonts/gilroy.scss';
@import './styles/_card';
@import './styles/_layout';

body {
  margin: 0;
  font-family: Gilroy, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100vh;
  background: white;
  font-family: sans-serif;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: none;
}

.screen {
  padding-top: 168px;
  padding-bottom: 20px;
}

#root {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

button {
  background-color: unset;
  border: none;
  appearance: none;
  cursor: pointer;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

.container {
  max-width: 1280px;
}
