@import '../../styles/mixins';

@mixin title {
  font-family: 'Futura New';
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--color-green-6);
  margin: 0;
}
@mixin input {
  background: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 4px;
  height: 38px;

  @include lt-md {
    width: 100%;
  }
}
@mixin infoSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0 23px 0;
  @include md {
    padding: 0 0 0 10px;
  }
  @include lt-sm {
    margin: 18px 0 0 0;
  }
}

.form {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 81px 78px 0 78px;

  @include md {
    padding: 81px 50px 0 50px;
  }

  @include lt-md {
    padding: 61px 40px 0 40px;
  }

  @include lt-md {
    padding: 17px 20px 0 20px;
  }

  .mainFormInfo {
    display: flex;
    justify-content: space-between;

    @include lt-md {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .info {
      margin: -35px 0 0 0;
      width: 528px;

      @include lt-md {
        width: 100%;
        margin: 23px 0 0 0;
      }

      .duration {
        display: flex;
        flex-direction: column;
        margin: 0 0 29px 0;
        @include md {
          padding: 0 0 0 10px;
        }
        @include lt-sm {
          margin: 18px 0 0 0;
        }
        .title {
          @include title;
          margin: 0 0 12px 0;
        }

        .subTitle {
          @include title;
          font-family: 'Gilroy';
          line-height: 24px;
          font-size: 20px;
          margin: 0 0 4px 0;
        }

        .durationInputs {
          display: flex;
          @include lt-md {
            justify-content: space-around;
          }

          @include lt-md {
            flex-direction: column;
          }

          input {
            @include input;
            width: 250px;
            margin: 0 5px;

            @include lt-sm {
              width: 100%;
            }
          }
        }
      }
      .select {
        @include infoSection;
        .title {
          @include title;
        }
        button {
          background: var(--color-white);
          border: 1px solid var(--color-black);
          border-radius: 4px;
          height: 38px;
        }
      }
    }
  }

  .secondaryFormInfo {
    .infoSectionTechnologies {
      @include infoSection;

      @include lt-sm {
        margin: 18px 0 0 0;
      }
      .title {
        @include title;
        margin: 30px 0 31px 0;

        @include lt-sm {
          margin: 0;
        }
      }
      input {
        @include input;
      }
    }

    .infoSectionScreenshots {
      @include infoSection;

      @include lt-sm {
        margin: 18px 0 0 0;
      }

      .title {
        @include title;
        margin-bottom: 27px;
      }
    }
  }

  .submitContainer {
    display: flex;
    justify-content: center;
    margin: 42px 0 72px 0;

    @include lt-sm {
      margin: 53px 0 55px 0;
    }
    .submit {
      color: var(--color-white);
      background: var(--color-red);
    }
  }
}
