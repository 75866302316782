@import '../../styles/mixins';

@mixin title {
  font-family: 'Futura New';
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--color-green-6);
  margin: 0;
}
@mixin input {
  background: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 4px;
  height: 38px;

  @include lt-md {
    width: 100%;
  }
}
@mixin infoSection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0 23px 0;
}
@mixin toDropArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .imageContainer {
    margin: 107px 0 0 0;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    @include md {
      margin: 80px 0 0 0;
    }

    @include lt-sm {
      margin: 67px 0 0 0;
      width: 140px;
      height: 120px;
    }
  }
}

.form {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 81px 78px 0 78px;

  @include md {
    padding: 81px 50px 0 50px;
  }

  @include lt-md {
    padding: 61px 40px 0 40px;
  }

  @include lt-md {
    padding: 17px 20px 0 20px;
  }

  .mainFormInfo {
    display: flex;
    justify-content: space-between;

    @include lt-md {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .info {
      width: 528px;
      @include lt-md {
        width: 100%;
        margin: 23px 0 0 0;
      }
      .title {
        @include title;
      }
      .position {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 0 23px 0;
        @include md {
          padding: 0 0 0 10px;
        }
        @include lt-sm {
          margin: 18px 0 0 0;
        }
        .select {
          background: var(--color-white);
          border: 1px solid var(--color-black);
          border-radius: 4px;
          height: 38px;
        }
      }
    }
  }

  .secondaryFormInfo {
    .infoSectionTechnologies {
      @include infoSection;

      @include lt-sm {
        margin: 18px 0 0 0;
      }
      .title {
        @include title;
        margin: 30px 0 31px 0;

        @include lt-sm {
          margin: 0;
        }
      }
      input {
        @include input;
      }
    }

    .infoSectionPdf {
      @include infoSection;

      @include lt-sm {
        margin: 18px 0 0 0;
      }

      .title {
        @include title;
        margin-bottom: 27px;
      }
    }
  }
  .submitContainer {
    display: flex;
    justify-content: center;
    margin: 42px 0 72px 0;

    @include lt-sm {
      margin: 53px 0 55px 0;
    }
    .submit {
      color: var(--color-white);
      background: var(--color-red);
    }
  }
}
