.skill {
  padding: 3px 18px;
  background: rgba(12, 121, 137, 0.1);
  border-radius: 39px;
}

.skillName {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: 0.045em;
  color: #000000;
}
