.cv-preview-card {
  padding: 26px 41px 26px 35px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
  position: relative;
  height: 100%;

  &--header {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  &--body {
    margin-bottom: 56px;
  }

  &--footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }

  &--delimiter {
    border-bottom: 1px solid #004959;
  }

  &--photo-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    .icon {
      object-fit: contain;
    }
  }

  &--photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: 155%;
    background-position-x: -36px;
    background-position-y: -9px;
    background-repeat: no-repeat;
  }

  &--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  &--fullname,
  &--role {
    img {
      margin-right: 7px;
    }
  }

  &--fullname {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #004959;
  }
  &--role {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #004959;
  }

  &--technologies {
    padding: 30px 0 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 8px;
  }

  &--expirience {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #000000;

    &-container {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
}
