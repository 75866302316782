.pageTitle {
  width: 100%;
  padding: 29px 0 14px;
  margin-bottom: 60px;

  font-family: 'Futura New';
  font-size: 40px;
  font-weight: 600;
  line-height: 97%;
  text-transform: uppercase;
  text-align: center;
  color: #0a3944;

  border-bottom: 2px solid #004959;
}
