.download-button {
  width: auto !important;
  justify-content: center;
  align-content: center;

  span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-right: 40px;
  }

  button {
    background: none;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    color: #d25f4d;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    img {
      margin-right: 20px;
    }
  }
}
