.login-form_input {
  width: 372px;
  padding: 13px 0;
  label {
    font-family: var(--font-futura-new);
    font-weight: 600;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-green-1);
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.025));
    border-radius: 57px;
    height: 50px;
    padding: 0 1.75rem;
    &:focus {
      border-color: var(--color-green-light-1);
      box-shadow: 0 0 0 0.075rem var(--color-green-4);
    }
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
